import { Link, Head } from '@inertiajs/react';
import { PageProps } from '@/types';
import { Icon } from '@iconify/react';
import Header from '@/Layouts/Partials/Header';
import Footer from '@/Layouts/Partials/Footer';
import { SettingModel } from '@/types/models/setting';
import FrontLayout from '@/Layouts/FrontLayout';
import { useEffect, useState } from 'react';
import HeroSlider from '@/Components/Homepage/HeroSlider';
import SliderWithDescription from '@/Components/Homepage/SliderWithDescription';
import ChoiceOnMedal from '@/Components/Homepage/ChoiceOnMedal';
import LandscapeWithBox from '@/Components/Homepage/LandscapeWithBox';
import IconsWithTexts from '@/Components/Homepage/IconsWithTexts';
import InvestmentOnMedal from '@/Components/Homepage/InvestmentOnMedal';
import FindApartment from '@/Components/Homepage/FindApartment';
import Facilities from '@/Components/Homepage/Facilities';
import ApartmentSearch from '@/Components/Homepage/ApartmentSearch';
import { SearchEngineData } from '@/types/searchEngineData';
import { GalleryModel } from '@/types/models/gallery';
import GallerySlider from '@/Components/Homepage/GallerySlider';
import News from '@/Components/Homepage/News';
import { PostModel } from '@/types/models/post';
import AboutInvestor from '@/Components/Homepage/AboutInvestor';
import ContactForm from '@/Components/Universal/ContactForm';
import InvestmentLocation from '@/Components/Homepage/InvestmentLocation';

interface Slide {
    image: string;
    heading: string;
    text: string;
    buttonLink: string;
    buttonText: string;
}

export default function Index({ sliderSetting, searchEngineParams, galleryImages, news }: PageProps<{
    sliderSetting: SettingModel, searchEngineParams: SearchEngineData, galleryImages: GalleryModel[],
    news: PostModel[]
}>) {
    const [parsedContent, setParsedContent] = useState<Slide[]>();

    useEffect(() => {
        if(sliderSetting?.content != ''){
            setParsedContent(JSON.parse(sliderSetting.content))
        }
    }, [sliderSetting])
    
    return (
        <>
            <Head title="Osiedle Kusocińskiego">
                <meta name='description' content='Osiedle Kusocińskiego'/>
            </Head>
            <FrontLayout>
                <HeroSlider slides={parsedContent}/>
                <SliderWithDescription/>
                <ChoiceOnMedal/>
                <LandscapeWithBox/>
                <IconsWithTexts/>
                <FindApartment/>
                <InvestmentOnMedal/>
                <Facilities/>
                <InvestmentLocation/>
                <ApartmentSearch searchEngineParams={searchEngineParams}/>
                <GallerySlider images={galleryImages}/>
                <News posts={news}/>
                <AboutInvestor/>
                <ContactForm/>
            </FrontLayout>
        </>
    );
}
