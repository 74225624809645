import { Link } from "@inertiajs/react";
import Column from "../Grid/Column";
import Container from "../Grid/Container";
import Row from "../Grid/Row";
import WebpImage from "../WebpImage";
import { Suspense, lazy, useEffect, useRef, useState } from "react";
import useIsServer from "@/Hooks/useIsServer";
import ReactCurvedText from "react-curved-text";

const Loader = () => {
    return (
        <p>Ładowanie...</p>
    )
}

const FirstCurvedText = () => {
    const [Component, setComponent] = useState(() => Loader)

    useEffect(() => {
        // @ts-expect-error
        setComponent(() => lazy(() => import('react-curved-text')))
    }, [])

    return (
        <Suspense fallback={<Loader />}>
            <Component
                // @ts-ignore
                width={200}
                height={109}
                cx={100}
                cy={5}
                rx={100}
                ry={100}
                startOffset={13}
                reversed={false}
                text='ZNAJDŹ MIESZKANIE DLA SIEBIE'
                textProps={{ "style": { "fontSize": "16px", letterSpacing: '1.5px' } }}
                textPathProps={{ "fill": "#004053" }}
                tspanProps={null}
                ellipseProps={null}
                svgProps={null} />
        </Suspense>
    )
}

const SecondCurvedText = () => {
    const [Component, setComponent] = useState(() => Loader)

    useEffect(() => {
        // @ts-expect-error
        setComponent(() => lazy(() => import('react-curved-text')))
    }, [])

    return (
        <Suspense fallback={<Loader />}>
            <Component
                // @ts-ignore
                width={200}
                height={109}
                cx={100}
                cy={5}
                rx={100}
                ry={100}
                startOffset={13}
                reversed={false}
                text='ZNAJDŹ MIESZKANIE DLA SIEBIE'
                textProps={{ "style": { "fontSize": "16px", letterSpacing: '1.5px' } }}
                textPathProps={{ "fill": "#004053" }}
                tspanProps={null}
                ellipseProps={null}
                svgProps={null} />
        </Suspense>
    )
}

const FindApartment = () => {
    return (
        <section className="lg:px-14 md:px-4 maxMd:px-0 flex maxMd:flex-wrap gap-x-6 mb-52 maxMd:mb-38">
            <div className="max-w-[42%] maxMd:max-w-[100%] relative maxMd:mb-6">
                <WebpImage
                    width={757}
                    height={712}
                    fallback="/storage/static/find-apartment-1.jpg"
                    lazy
                    alt="Taras oraz ludzie"
                />
                <div className="absolute -right-16 -bottom-14 transition-all ease-in-out duration-300 hover:-translate-y-4 cursor-pointer maxMd:hidden z-30">
                    <Link href={route('frontend.apartments')} as="button" className="glass-style p-7 rounded-full" title="Wyszukiwarka mieszkań">
                        <img src="/storage/icons/silhouette.svg" alt="Ikona metraż" width={64} height={64} />
                        <div className='absolute -left-10 -bottom-11'>
                            <FirstCurvedText />
                        </div>
                    </Link>


                </div>
            </div>
            <div className="max-w-[58%] maxMd:max-w-[100%] relative">
                <WebpImage
                    width={1027}
                    height={712}
                    fallback="/storage/static/find-apartment-2.jpg"
                    lazy
                    alt="Taras oraz kobieta"
                />
                <div className="absolute left-1/2 -translate-x-1/2 -bottom-14 transition-all ease-in-out duration-300 hover:-translate-y-4 cursor-pointer maxMd:block hidden">
                    <Link href={route('frontend.apartments')} as="button" className="glass-style p-7 rounded-full" title="Wyszukiwarka mieszkań">
                        <img src="/storage/icons/silhouette.svg" alt="Ikona metraż" width={64} height={64} />
                        <div className='absolute -left-10 -bottom-11'>
                            <SecondCurvedText />
                        </div>
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default FindApartment;